import {
    loadTipOfTheDayFailureAction,
    loadTipOfTheDaySuccessAction,
    TipOfTheDayActionTypes,
    updateTipOfTheDayFailureAction,
    updateTipOfTheDaySuccessAction,
} from '@action/betting-tips/tip-of-the-day.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    ResponseResource,
    ResponseResourceCollection,
} from '@service/http/response';
import { Tip, TipProps, TipRequestParameters } from '@service/tip/tip';
import { TipService } from '@service/tip/tip.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { TipUpdateRequestParameters } from '../../core/services/tip/tip';

@Injectable()
export class TipOfTheDayEffects {
    private readonly payload = {
        tipOfTheDay: true,
        eventStatus: 'upcoming',
        limit: 1,
        page: 1,
    };

    constructor(
        private readonly actions$: Actions,
        private readonly tipService: TipService
    ) {}

    /**
     * Load tip of the day
     *
     * @returns Observable
     *
     * @author Rody Korthout <rkorthout@bettercollective.com>
     * @version 1.0.0
     */
    public loadTipOfTheDayEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TipOfTheDayActionTypes.LOAD_TIP_OF_THE_DAY),
            mergeMap((data: { payload: TipRequestParameters }) => {
                const payload = {
                    ...this.payload,
                    ...data.payload,
                };

                return this.tipService.index(payload).pipe(
                    map(
                        (response: ResponseResourceCollection<Tip>) => {
                            return loadTipOfTheDaySuccessAction({
                                data: response.data[0],
                            });
                        },
                        catchError((error: HttpErrorResponse) =>
                            of(
                                loadTipOfTheDayFailureAction({
                                    error,
                                }),
                            ),
                        ),
                    ),
                );
            }),
        ),
    );

    /**
     * Update tip
     *
     * @return {Observable}
     *
     * @author Rody Korthout <rkorthout@bettercollective.com>
     * @version 1.0.0
     */
    public updateTipEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TipOfTheDayActionTypes.UPDATE_TIP_OF_THE_DAY),
            mergeMap((data: TipProps<TipUpdateRequestParameters>) =>
                this.tipService.update(data.payload).pipe(
                    map((response: ResponseResource<Tip>) =>
                        updateTipOfTheDaySuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            updateTipOfTheDayFailureAction({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );
}
